(function ($) {
  "use strict";
  /*--
  Commons Variables
  -----------------------------------*/
  var windows = $(window);
  
  /*--
      Menu Sticky
  -----------------------------------*/
  var sticky = $('.header-sticky');
  
  windows.on('scroll', function() {
    var scroll = windows.scrollTop();
    if (scroll < 300) {
      sticky.removeClass('is-sticky');
    }else{
      sticky.addClass('is-sticky');
    }
  });
  
  /*--
      Header Search
  -----------------------------------*/
  var $headerSearchToggle = $('.header-search-toggle');
  var $headerSearchForm = $('.header-search-form');
  
  $headerSearchToggle.on('click', function() {
    var $this = $(this);
    if(!$this.hasClass('open')) {
      $this.addClass('open').find('i').removeClass('pe-7s-search').addClass('pe-7s-close');
      $headerSearchForm.slideDown();
    } else {
      $this.removeClass('open').find('i').removeClass('pe-7s-close').addClass('pe-7s-search');
      $headerSearchForm.slideUp();
    }
  });
  
  
  /*--
      Mobile Menu
  -----------------------------------*/
  var mainMenuNav = $('.main-menu');
  mainMenuNav.meanmenu({
    meanScreenWidth: '991',
    meanMenuContainer: '.mobile-menu',
    meanMenuClose: '<span class="menu-close"></span>',
    meanMenuOpen: '<span class="menu-bar"></span>',
    meanRevealPosition: 'right',
    meanMenuCloseSize: '0',
  });
  
  /*--
      Conatact Map
  -----------------------------------*/
  if($('.contact-map').length){
    function initialize() {
      var mapOptions = {
        zoom: 14,
        scrollwheel: false,
        center: new google.maps.LatLng(40.730610, -73.935242)
      };
      var map = new google.maps.Map(document.getElementById('contact-map'), mapOptions);
      var marker = new google.maps.Marker({
        position: map.getCenter(),
        map: map,
        animation: google.maps.Animation.BOUNCE
      });
      
    }
    google.maps.event.addDomListener(window, 'load', initialize);
  }
  
})(jQuery);
