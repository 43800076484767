const navbarInit = function () {
  $('.main-menu').meanmenu({
    meanScreenWidth: '991',
    meanMenuContainer: '.mobile-menu',
    meanMenuClose: '<span class="menu-close"></span>',
    meanMenuOpen: '<span class="menu-bar"></span>',
    meanRevealPosition: 'right',
    meanMenuCloseSize: '0',
  });
}

const videoInit = function () {

}

const pluginsInit = function () {
  
  $(".gallery-popup").magnificPopup({
    delegate: "a.item",
    type: "image",
    fixedContentPos: true,
    fixedBgPos: true,
    gallery: { enabled: true, tCounter: "" },
    image: {
      tError: '<a href="%url%">Foto #%curr%</a> nu s-a incarcat.',
      titleSrc: function (item) {
        return (
          item.el.data("name") + "<small>" + item.el.data("small") + "</small>"
        );
      },
    },
    zoom: { enabled: true },
  });
  
  $('.product-thumb-slider').slick({
    infinite: true,
    arrows: false,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: '<button class="slick-prev"><i class="fa fa-angle-left"></i></button>',
    nextArrow: '<button class="slick-next"><i class="fa fa-angle-right"></i></button>',
    asNavFor: '.product-image-slider',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 4,
        }
      }
    ]
  });
  
  $('.product-image-slider').slick({
    infinite: true,
    arrows: false,
    dots: false,
    draggable: false,
    swipe: false,
    touchMove: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.product-thumb-slider',
  });
  
  $('.video-popup').magnificPopup({
    type: 'iframe',
  });
}

document.addEventListener("turbolinks:load", function() {
  navbarInit();
  videoInit();
  pluginsInit();
})
