require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('trix')
require('@rails/actiontext')
require('channels');
require('jquery');
require('bootstrap');
require('svg-injector');

import SVGInjector from 'svg-injector'
import 'bootstrap';
import 'font-awesome/css/font-awesome.css';

window.jQuery = $;
window.$ = $;
window.SVGInjector = SVGInjector

import '../js/lib/popup'
import '../js/plugins'
import '../js/main'
import '../js/index'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "../stylesheets/main.scss"
